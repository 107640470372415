<template>

  <section id="gallery-video">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/videos/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Subir video
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-7 input-search mb-3 mb-lg-0">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar video" 
                v-model.trim="filter.search" 
                ref="searchInput"
                @keypress.enter="getPaginatedVideoIds()">
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-12 col-lg-5">
            <v-select 
              class="selvue-custom"
              :options="categorias" 
              v-model="filter.sel_categoria"
              label="nombre_categoria"
              placeholder="Selecciona una categoría"
              :clearable="true">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <VideoCard 
          :videos="lista_videos" 
          :open_preview="open_preview" 
          @editOpenPrev="editOpenPrev"
          @videoDeleted="onVideoDelete"
          :loading="loading"
        />
      </div>
    </div>

    <SinResultados
      msg="Aceptar" 
      custom-click 
      @click="nuevaBusqueda"  
      v-if="!loading && lista_videos.length === 0"/>

    <!-- pagination -->
    <div class="users-list__pagination" v-if="pagination.page_count > 0">
      <paginate
        v-model="pagination.actual_page"
        :page-count="videoVideosPagination.length"
        :page-range="pagination.page_range"
        :click-handler="pagination.click_handler"
        :prev-text="pagination.prev_text"
        :next-text="pagination.next_text"
        :container-class="pagination.container_class"
        :page-class="pagination.page_class"
      >
      </paginate>
    </div>
  </section>

</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import VideoCard from "@/components/VideosSection/VideoCard.vue";
  import moment from "moment";
  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      VideoCard, 

      //FF
      InputDate,
      SinResultados
    },

    data() {
      return {
        subtitle:
          "Conoce todos los videos que están publicados en Linkiwork. Podrás editar sus descripciones o eliminarlos si lo requieres.",
        pagination: {
          actual_page: 1,
          page_count: 1,
          page_range: 3,
          click_handler: this.onPageChange,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class: "pagination-item",
        },
        open_preview: false,
        img_calendar: require("@/assets/img/utilities/calendar.svg"),
        init_date: "Inicio",
        end_date: "Término",
        max_date: "",
        min_date: "",
        calendar_side: "",
        id_usuario: this.$ls.get("user").id_usuario,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        show_modal_view: false,
        video_selected: {},
        show_modal: false,
        categorias: [],
        lista_videos: [],
        show_calendar: false,
        filesPerPage: "10",
        title: "",
        cat_selected: null,

        //FF
        loading: true,
        filter: { 
          search: '',
          sel_categoria: null,
        },
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },

    computed: {
      ...mapState("videoModule", ["videoVideosPagination", "allVideoVideos"]),
      ...mapGetters("videoModule", ["videoVideosFiltered", "paginationDataForVideoVideos"]),
      
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      }
    },

    watch: {
      "filter.search"(value) {
        if(value === '') {
          this.getPaginatedVideoIds();
        }
      },
      "filter.sel_categoria"() {
        this.getPaginatedVideoIds();
      },
      "range.date_start"() {
        this.getPaginatedVideoIds();
      },
      "range.date_end"() {
        this.getPaginatedVideoIds();  
      },
      allVideoVideos() {
        this.lista_videos = this.videoVideosFiltered(this.pagination.actual_page-1);
      }
    },

    async created() {
      await this.getCategories();
      await this.getAllVideos();
      await this.getPaginatedVideoIds();
    },

    methods: {
      ...mapActions("videoModule", ["addVideoAction", "getPagination", "getAllVideoVideos", "getCategoriesAction"]),
      nuevaBusqueda(){
        this.filter.search = '';
        this.$refs.searchInput.focus();
        this.deleteDate();
      },
      async getAllVideos() {
        this.loading = true;
        const data = {
          idEmpresa: this.id_empresa,
          limite: this.filesPerPage,
          idCategoria: this.filter.sel_categoria !== null ? this.filter.sel_categoria.id_categoria : "0",
          fechaInicio: this.range.date_start !== null ? this.range.mask_start : '',
          fechaFinal: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
        };
        const res = await this.getAllVideoVideos(data);
        if (!res) {
          this.toastGetVideosError();
        }
        this.loading = false;
      },

      editOpenPrev(state) {
        this.open_preview = state;
      },

      deleteDate() {
        this.init_date = "Inicio";
        this.end_date = "Término";
        this.getPaginatedVideoIds();
        this.show_calendar = false;
      },

      //mostrar modal de video
      showVideo(video) {
        this.show_modal_view = true;
        this.video_selected = video;
      },

      async getCategories() {
        const payload = {
          idEmpresa: this.id_empresa,
          plataforma: "2",
        };

        this.categorias = await this.getCategoriesAction(payload);
        if (this.categorias === null) {
          this.toastGetVideosError();
        } 
      },

      async onPageChange(page) {
        
        this.loading = true;
        this.pagination.actual_page = page;
        await this.getVideoVideosForPage(page);
        this.loading = false;
      },

      async getPaginatedVideoIds() {
        this.loading = true;
        const payload = {
          idEmpresa: this.id_empresa,
          nombre: this.filter.search,
          fechaInicio: this.range.date_start !== null ? this.range.mask_start : '',
          fechaFinal: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          idCategoria: this.filter.sel_categoria !== null ? this.filter.sel_categoria.id_categoria : "0",
          limite: this.filesPerPage
        };
        await this.getPagination(payload);
        if (this.videoVideosPagination === null) {
          this.toastGetVideosError();
        } else {
          if (this.videoVideosPagination.length > 0) {
            this.getVideoVideosForPage(1);
          }
          else{
            this.lista_videos = [];
          }
        }
        this.loading = false;
      },

      async getVideoVideosForPage(page) {
        this.lista_videos = this.videoVideosFiltered(page-1);
      },

      async onVideoDelete() {
        await this.getPaginatedVideoIds();
        this.pagination.actual_page = 1;
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      }
    },
  };
</script>
